import React from "react";
import logo from "./logo.svg";
import "./App.css";
import axios, { AxiosError } from "axios";
import { RequestOptions } from "http";
import classNames from "classnames";
import CryptoES from "crypto-es";

// const firmware = require("../src/RogaFirmware.v1.1.2.uf2");

interface RequestInitWithTimeout extends RequestInit {
  timeout?: number;
}

async function fetchWithTimeout(
  resource: RequestInfo | URL,
  options: RequestInitWithTimeout = {}
) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

const buttonTemplate = "p-2 bg-gray-500 rounded";
const rowTemplate =
  "flex flex-row justify-around mb-4 border-b-2 border-black ";

function App() {
  const [devicePresent, setDevicePresent] = React.useState(false);
  const [deviceInFlashMode, setDeviceInFlashMode] = React.useState(false);
  const [directoryHandle, setDirectoryHandle] =
    React.useState<FileSystemDirectoryHandle | null>(null);
  const [flashInProgress, setFlashInProgress] = React.useState(false);
  const [flashComplete, setFlashComplete] = React.useState(false);
  const checkDevicePresent = async () => {
    // try {
    //   const response = await axios.get("http://192.168.7.1/status.json", {
    //     timeout: 500,
    //    });
    //   console.log(response.status);
    //   console.log(response.data);
    // } catch (error) {
    //   const axiosError = error as AxiosError;
    //   console.error(axiosError.message, axiosError.code);
    //   console.log(error);
    // }

    try {
      //make a no-cors fetch instead
      const response = await fetchWithTimeout(
        "http://192.168.7.1/status.json",
        {
          mode: "no-cors",
          timeout: 500,
        } as RequestInit
      );
      console.log(response.status);
      const text = await response.text();
      console.log(text);
      setDevicePresent(true);
    } catch (error) {
      console.error(error);
      setDevicePresent(false);
    }
  };

  // async function* getFilesRecursively(entry) {
  //   if (entry.kind === "file") {
  //     const file = await entry.getFile();
  //     if (file !== null) {
  //       file.relativePath = getRelativePath(entry);
  //       yield file;
  //     }
  //   } else if (entry.kind === "directory") {
  //     for await (const handle of entry.values()) {
  //       yield* getFilesRecursively(handle);
  //     }
  //   }
  // }

  const activateFlashMode = async (): Promise<boolean> => {
    try {
      const response = await fetchWithTimeout("http://192.168.7.1/flash", {
        mode: "no-cors",
        timeout: 100,
      } as RequestInit);
      console.log(response.status);
    } catch (e) {
      const error = e as Error;
      if (error.name === "AbortError") {
        return true;
      }
    }
    return false;
  };

  const openDirectory = async (): Promise<string[]> => {
    const fileNames: string[] = [];
    const dirHandle = await window.showDirectoryPicker({ mode: "readwrite" });
    setDirectoryHandle(dirHandle);
    for await (const entry of dirHandle.values()) {
      fileNames.push(entry.name);
      console.log(entry.name);
    }
    return fileNames;
  };

  const startFlashProcess = async () => {
    setDeviceInFlashMode(false);
    const activationResult = await activateFlashMode();
    const fileNames = await openDirectory();
    if (fileNames.includes("INFO_UF2.TXT")) {
      setDeviceInFlashMode(true);
    }
  };

  const flashDevice = async () => {
    setFlashComplete(false);

    const firmwareDownload = await fetch(
      "https://storage.googleapis.com/roga-firmware-images/RogaFirmware.v1.1.2.uf2"
    );
    const firmware = await firmwareDownload.arrayBuffer();
    const hash = CryptoES.SHA256(CryptoES.lib.WordArray.create(firmware));
    const goodHash =
      "a9852d3b5dc05850b92d0692a4d531a5b8cb74626077a0d645f3f0be467fc8f0";
    const hashVerified = hash.toString() === goodHash;
    console.log("firmware binary downloaded, length: ", firmware.byteLength);
    if (hashVerified) console.log("firmware hash verified: ", hash.toString());
    else console.log("firmware hash mismatch: ", hash.toString());

    // const dirHandle = await window.showDirectoryPicker({mode: "readwrite"});

    if (directoryHandle && hashVerified) {
      try {
        setFlashInProgress(true);
        const fileHandle = await directoryHandle.getFileHandle("newImage.uf2", {
          create: true,
        });
        const writable = await fileHandle.createWritable();
        await writable.write(firmware);
        await writable.close();
        console.log("done writing");
        setFlashComplete(true);
        setFlashInProgress(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div className="h-full bg-amber-900 flex-col p-4 ">
      <div className={"h-1/3 text-4xl flex flex-col items-center"}>
        <div>Roga prototype firmware update tool.</div>
        <div>This tool is not ready for release.</div>
        <div className={"underline"}>Do not share it with customers</div>
      </div>
      <div className={"border-2 border-amber-500 mt-6"}>
        <div className={rowTemplate}>
          <button
            className={classNames(buttonTemplate)}
            onClick={checkDevicePresent}
          >
            Check device presence
          </button>
          <div className={"p-2 w-1/4 border-2"}>
            {devicePresent
              ? "Device present and in normal mode"
              : "Device not present or is in flash mode"}
          </div>
        </div>
        <div className={rowTemplate}>
          <button className={buttonTemplate} onClick={startFlashProcess}>
            Enable flash mode
          </button>
          <div className={"w-1/4"}>
            Select the RPI-RP2 drive on your computer and grant permission to
            edit files
          </div>
          <div className={"p-2 w-1/4 border-2"}>
            {deviceInFlashMode
              ? "Device present in flash mode"
              : "Device not present or not in flash mode"}
          </div>
        </div>
        <div className={rowTemplate}>
          <button className={buttonTemplate} onClick={flashDevice}>
            Flash device
          </button>
          <div className={"p-2 w-1/4 border-2 flex flex-col "}>
            <div>{flashInProgress && "Flashing in progress"}</div>
            <div>
              {flashComplete
                ? "Device flashing completed"
                : "Device flashing not completed"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
